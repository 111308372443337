import axios from 'axios';

const CLIENT_ID = 'nwnvQxFx8iKv5BwzHUKs';

const baseDomain =
  process.env.NEXT_PUBLIC_APP_ENV !== 'production'
    ? 'https://staging.api.goodcreator.co'
    : 'https://saas.goodcreator.co';

const baseURL = `${baseDomain}/`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'x-bb-clientid': CLIENT_ID,
  'x-bb-channelid': 'ERP',
  'x-bb-portal': 'VIDOOLY',
};

const axiosInstance = axios.create({
  baseURL,
  headers,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (typeof window !== 'undefined' && Cookies.get('user')) {
      const cookie = JSON.parse(Cookies.get('user'));
      config.headers['Authorization'] = `Bearer ${cookie.userToken}`;
    }

    config.headers['x-bb-deviceid'] =
      (typeof window !== 'undefined' &&
        window.localStorage.getItem('deviceId')) ||
      '';

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
