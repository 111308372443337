import GeneralRepository from './GeneralRepository';
import DiscoveryRepository from './DiscoveryRepository';
import LeaderboardRepository from './LeaderboardRepository';
import CollectionRepository from './CollectionRepository';
import AuthRepository from './AuthRepository';
import AccountRepository from './AccountRepository';
import InfluencerReportRepository from './InfluencerReportRepository';
import GenreRepository from './GenreRepository';
import UploadImageRepository from './uploadImageRepository';
import CampaignRepository from './CampaignReportRepository';

const repositories = {
  general: GeneralRepository,
  discovery: DiscoveryRepository,
  leaderboard: LeaderboardRepository,
  collection: CollectionRepository,
  auth: AuthRepository,
  influencer: InfluencerReportRepository,
  account: AccountRepository,
  genre: GenreRepository,
  uploadImage: UploadImageRepository,
  campaign: CampaignRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
