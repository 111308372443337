import client2 from './apolloClient2';
import gql from 'graphql-tag';

const apis ={
  getPresignedUrl(params) {
    return client2.mutate({
      mutation: gql`
        mutation init(
          $usage: AssetUsage!
          $type: AssetType!
          $source: String
          $sourceId: String
        ) {
          init(
            usage: $usage
            type: $type
            source: $source
            sourceId: $sourceId
          ) {
            path
            url
          }
        }
      `,
      variables: {
        usage: params.usage,
        type: params.type,
        source: params.source,
        sourceId: params.sourceId,
      },
    });
  },
  finishUpload(params) {
    return client2.mutate({
      mutation: gql`
        mutation finishAssetUpload(
          $path: String!
          $width: Float
          $height: Float
          $duration: Int
        ) {
          finish(
            path: $path
            width: $width
            height: $height
            duration: $duration
          ) {
            id
            url
          }
        }
      `,
      variables: {
        path: params.path,
      },
    });
  },
};

export default apis;
