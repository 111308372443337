import {
  ApolloClient,
  InMemoryCache,
  //   IntrospectionFragmentMatcher,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const CLIENT_ID = 'nwnvQxFx8iKv5BwzHUKs';

export const CLIENT_HEADERS = {
  'content-type': 'application/json',
  accept: 'application/json',
  'x-bb-clientid': CLIENT_ID,
  'x-bb-channelid': 'WEB',
  'x-apollo-operation-name': 'initDeviceV2',
  'x-bb-clienttype': 'BRAND',
  'x-bb-portal': 'VIDOOLY',
  'X-Bb-Version': 1,
};

const authLink = setContext(async ({ operationName }, { headers }) => {
  let token = null;
  // eslint-disable-next-line no-undef
  if (typeof window !== 'undefined' && Cookies.get('user')) {
    // eslint-disable-next-line no-undef
    const cookie = JSON.parse(Cookies.get('user'));
    token = cookie.userToken;
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
      ...CLIENT_HEADERS,
      'x-bb-deviceid':
        (typeof window !== 'undefined' &&
          window.localStorage.getItem('deviceId')) ||
        '',
      'x-apollo-operation-name': operationName,
      ...headers,
    },
  };
});

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData: {
//     __schema: {
//       types: [
//         {
//           kind: 'INTERFACE',
//           name: 'WidgetData',
//           possibleTypes: [
//             { name: 'IconWidgetData' },
//             { name: 'BannerWidgetData' },
//             { name: 'ChipItemWidgetData' },
//           ],
//         },
//       ],
//     },
//   },
// });

//resetEND
if (process.env.NEXT_PUBLIC_APP_ENV !== 'production')
  var baseDomain = 'https://stageapi.goodcreator.co/graphql';
else baseDomain = 'https://api.goodcreator.co/graphql';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};
const httpLink = new HttpLink({
  uri: baseDomain,
  credentials: 'same-origin',
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
  queryDeduplication: false,
});

export default client;
