import Repository from './Repository';

const apis = {
  getBasicInfo(code, apiParams) {
    if (apiParams.isShared)
      return Repository.get(`discovery-service/api/profile/share/${code}`);
    return Repository.get(`discovery-service/api/profile/${code}`);
  },
  getOverview(payload, apiParams) {
    if (apiParams.isShared)
      return Repository.get(
        `discovery-service/api/profile/share/${payload.code}`
      );

    return Repository.get(
      `discovery-service/api/profile/${payload.platform}/${payload.code}`
    );
  },
  getAudienceInfo(payload, apiParams) {
    if (apiParams.isShared)
      return Repository.get(
        `discovery-service/api/profile/share/${payload.code}/audience`
      );

    return Repository.get(
      `discovery-service/api/profile/${payload.platform}/${payload.code}/audience`
    );
  },
  getGrowthInfo(payload, apiParams) {
    if (apiParams.isShared)
      return Repository.post(
        `discovery-service/api/profile/share/${apiParams.code}/timeseries?sortBy=date&sortDir=ASC`,
        payload
      );
    return Repository.post(
      `discovery-service/api/profile/${apiParams.platform}/${apiParams.code}/timeseries?sortBy=date&sortDir=ASC`,
      payload
    );
  },
  getContentInfo(code, apiParams) {
    if (apiParams.isShared)
      return Repository.post(
        `discovery-service/api/profile/share/${code}/content?page=${apiParams.page}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`
      );
    return Repository.post(
      `discovery-service/api/profile/${apiParams.platform}/${code}/content?page=${apiParams.page}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`
    );
  },
  getHashtagsInfo(payload, apiParams) {
    if (apiParams.isShared)
      return Repository.get(
        `discovery-service/api/profile/share/${payload.code}/hashtags`
      );
    return Repository.get(
      `discovery-service/api/profile/${payload.platform}/${payload.code}/hashtags`
    );
  },
  getSimilarAccounts(payload, apiParams) {
    if (apiParams.isShared)
      return Repository.post(
        `discovery-service/api/profile/share/${apiParams.code}/similar_accounts?page=${apiParams.page}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`,
        payload
      );

    return Repository.post(
      `discovery-service/api/profile/${apiParams.platform}/${apiParams.code}/similar_accounts?page=${apiParams.page}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`,
      payload
    );
  },
  getProfileUnlockStatus(apiParams, body) {
    return Repository.post(
      `/partner-usage-service/api/partner/profile/${apiParams.id}/${apiParams.platform}/unlock`,
      body
    );
  },
};

export default apis;
