import Repository from './Repository';

const apis = {
  createPostCollection(apiParams, body) {
    return Repository.post(`post-collection-service/api/collection/`, body);
  },
  getPostCollections(body, apiParams) {
    return Repository.post(
      `post-collection-service/api/collection/search?cursor=${apiParams.cursor}&size=${apiParams.size}`,
      body
    );
  },
  getPostCollection(id) {
    return Repository.get(`post-collection-service/api/collection/${id}`);
  },
  getSharedPostCollection(id) {
    return Repository.get(
      `post-collection-service/api/collection/byShareId/${id}`
    );
  },
};

export default apis;
