import Repository from './Repository';
import axios from 'axios';

const CancelToken = axios.CancelToken;

var apiRequestsToken = {};

function setApiRequestToken(payload) {
  if (!(payload.key in apiRequestsToken)) {
    createApiToken(payload);
  } else updateApiToken(payload);
}

function createApiToken(payload) {
  apiRequestsToken[payload.key] = {
    activeToken: payload.token,
  };
}

function updateApiToken(payload) {
  let cancel = apiRequestsToken[payload.key]['activeToken'];
  apiRequestsToken[payload.key]['activeToken'] = payload.token;
  cancel();
}

const apis = {
  getInfluencers(body, apiParams) {
    return Repository.post(
      `leaderboard-service/api/leaderboard/${apiParams.platform}?cursor=${apiParams.cursor}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`,
      body,
      {
        cancelToken: new CancelToken(function executor(c) {
          setApiRequestToken({
            key: `leaderboard-service/api/leaderboard/getInfluencers`,
            token: c,
          });
        }),
      }
    );
  },
};

export default apis;
