import Repository from './Repository';
import axios from 'axios';

const CancelToken = axios.CancelToken;

var apiRequestsToken = {};

function setApiRequestToken(payload) {
  if (!(payload.key in apiRequestsToken)) {
    createApiToken(payload);
  } else updateApiToken(payload);
}

function createApiToken(payload) {
  apiRequestsToken[payload.key] = {
    activeToken: payload.token,
  };
}

function updateApiToken(payload) {
  let cancel = apiRequestsToken[payload.key]['activeToken'];
  apiRequestsToken[payload.key]['activeToken'] = payload.token;
  cancel();
}

const apis = {
  getGenreOverview(apiParams, body) {
    return Repository.post(`genre-insights-service/api/genre/search`, body);
  },
  getGenreLanguageSplit(platform, body) {
    return Repository.post(
      `/genre-insights-service/api/genre/${platform}/language_split?size=10&sortBy=views&sortDir=DESC`,
      body
    );
  },
  getTrendingContent(apiParams, body) {
    return Repository.post(
      `/content-service/api/content/trending_content?size=10&sortBy=views&sortDir=DESC`,
      body
    );
  },
  getGenreDemand(apiParams, body) {
    return Repository.post(
      `/genre-insights-service/api/genre/search?size=10&sortBy=${apiParams.sortBy}&sortDir=DESC`,
      body
    );
  },
  getGenreTopSocialProfiles(body, apiParams, apiType) {
    return Repository.post(
      apiType === 'DISCOVERY'
        ? `discovery-service/api/profile/${apiParams.platform}/search?cursor=${apiParams.cursor}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`
        : `leaderboard-service/api/leaderboard/${apiParams.platform}?cursor=${apiParams.cursor}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`,
      body,
      {
        cancelToken: new CancelToken(function executor(c) {
          setApiRequestToken({
            key: 'getGenreTopSocialProfiles',
            token: c,
          });
        }),
      }
    );
  },
};

export default apis;
