import client from './apolloClient';
import gql from 'graphql-tag';

const apis = {
  updateAccount(params) {
    return client.mutate({
      mutation: gql`
        mutation updateAccount($input: UserAccountInput!) {
          updateAccount(input: $input) {
            name
            phone
          }
        }
      `,
      variables: {
        input: {
          name: params.name,
          partnerProfile: params.partnerProfile,
        },
      },
    });
  },
  inviteMember(params) {
    return client.mutate({
      mutation: gql`
        mutation inviteMember($input: MemberInviteInput!) {
          inviteMember(input: $input) {
            status
          }
        }
      `,
      variables: {
        input: {
          memberEmail: params.email,
          partnerId: Number(params.partnerId),
          partnerClientId: params.partnerClientId,
          permissions: [],
          isAdmin: params.isAdmin,
        },
      },
    });
  },
  reinviteUser(params) {
    return client.mutate({
      mutation: gql`
        mutation reInviteMember($inviteCode: ID!) {
          reInviteMember(inviteCode: $inviteCode) {
            memberEmail
            partnerId
            status
            expiresOn
            acceptedOn
          }
        }
      `,
      variables: {
        inviteCode: params,
      },
    });
  },
  searchMemberInvites(params) {
    return client.query({
      query: gql`
        query searchMemberInvites(
          $query: SearchQuery!
          $page: Int!
          $size: Int!
        ) {
          searchMemberInvites(query: $query, page: $page, size: $size) {
            edges {
              node {
                memberEmail
                partnerId
                status
                expiresOn
                acceptedOn
                code
              }
            }
            pageInfo {
              hasNextPage
              total
              totalFiltered
              pageNo
            }
          }
        }
      `,
      variables: {
        query: params.query,
        page: params.page,
        size: params.size,
      },
    });
  },
  createLead(payload) {
    return client.mutate({
      mutation: gql`
        mutation createLead($input: LeadInput!) {
          createLead(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: payload,
      },
    });
  },
  changePassword(params) {
    return client.mutate({
      mutation: gql`
        mutation changePassword($input: ChangePasswordRequest!) {
          changePassword(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: {
          currentPassword: params.currentPassword,
          newPassword: params.newPassword,
        },
      },
    });
  },
};

export default apis;
