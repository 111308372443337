import client from './apolloClient';
import gql from 'graphql-tag';
const clientSecret = '85OSbuXe7XoUJVWPNp8lfGWQCtcfHUeDaR3OdKmD';
import Repository from './Repository';

const apis = {
  initDevice(payload) {
    return client.mutate({
      mutation: gql`
        mutation initDeviceV2Mutation($input: InitDeviceInput!) {
          initDeviceV2(input: $input) {
            userToken
            refreshTime
            userRefreshToken
            loggedIn
            userAccount {
              accountType
              email
              phone
              name
              id
              permissions
              webengageUserId
              ... on ERPAccount {
                accountType
                visitedDemo
                partnerUserProfile {
                  partner {
                    contracts {
                      type
                      plan
                      onboardingStatus
                    }
                  }
                }
                brand {
                  id
                  companyName
                  isOnboarded
                  whitelistedToolIds
                  logoUrl
                }
              }
            }
            credsLoginEnabled
          }
        }
      `,
      variables: {
        input: {
          ...payload,
          clientSecret,
        },
      },
    });
  },
  login(payload) {
    return client.mutate({
      mutation: gql`
        mutation loginV2($input: UserAuthInputV2!) {
          loginV2(input: $input) {
            status
            token
            refreshToken
            bbDeviceId
            isSignup
            preferences
            userAccount {
              accountType
              id
              name
              email
              phone
              permissions
              ... on ERPAccount {
                accountType
                visitedDemo
                brand {
                  id
                  companyName
                  isOnboarded
                  whitelistedToolIds
                  logoUrl
                }
              }
            }
          }
        }
      `,
      variables: {
        input: {
          mode: 'EMAIL',
          ...payload,
        },
      },
    });
  },
  signup(payload) {
    return client.mutate({
      mutation: gql`
        mutation signupV2($input: UserAuthInputV2!) {
          signupV2(input: $input) {
            status
            token
            refreshToken
            bbDeviceId
            isSignup
            preferences
            userAccount {
              accountType
              id
              name
              email
              phone
              permissions
              ... on ERPAccount {
                accountType
                visitedDemo
                brand {
                  id
                  companyName
                  isOnboarded
                  whitelistedToolIds
                  logoUrl
                }
              }
            }
          }
        }
      `,
      variables: {
        input: payload,
      },
    });
  },
  createResetPasswordRequest(payload) {
    return client.mutate({
      mutation: gql`
        mutation createResetPasswordRequest($email: String!, $domain: String) {
          createResetPasswordRequest(email: $email, domain: $domain) {
            id
          }
        }
      `,
      variables: {
        email: payload.email,
        domain: payload.domain,
      },
    });
  },
  validateResetPasswordRequestByCode(payload) {
    return client.query({
      query: gql`
        query validateResetPasswordRequestByCode($code: String!) {
          validateResetPasswordRequestByCode(code: $code) {
            id
          }
        }
      `,
      variables: {
        code: payload,
      },
    });
  },
  resetPassword(payload) {
    return client.mutate({
      mutation: gql`
        mutation resetPassword($code: String!, $password: String!) {
          resetPassword(code: $code, password: $password) {
            id
          }
        }
      `,
      variables: {
        code: payload.code,
        password: payload.password,
      },
    });
  },
  validateMemberInviteByCode(payload) {
    return client.query({
      query: gql`
        query validateMemberInviteByCode($code: String!) {
          validateMemberInviteByCode(code: $code) {
            memberEmail
            status
          }
        }
      `,
      variables: {
        code: payload,
      },
    });
  },
  logoutV2() {
    return client.mutate({
      mutation: gql`
        mutation logoutV2 {
          logoutV2 {
            status
            token
            refreshToken
          }
        }
      `,
    });
  },
  createGCCLead(payload) {
    return client.mutate({
      mutation: gql`
        mutation createLead($input: LeadInput!) {
          createLead(input: $input) {
            id
            __typename
          }
        }
      `,
      variables: {
        input: {
          pageIdentifier: 'SMART_CAMPAIGNS',
          source:
            'UTM Source: source, UTM Campaign: campaign, UTM Medium: medium',
          ...payload,
        },
      },
    });
  },
  googleLogin(payload) {
    return client.mutate({
      mutation: gql`
        mutation loginV2($input: UserAuthInputV2!) {
          loginV2(input: $input) {
            token
            refreshToken
            bbDeviceId
            status
            preferences
            userAccount {
              accountType
              id
              permissions
              name
              email
              phone
              __typename
              ... on ERPAccount {
                accountType
                visitedDemo
                partnerUserProfile {
                  partner {
                    id
                    name
                  }
                }
                brand {
                  id
                  companyName
                  isOnboarded
                  whitelistedToolIds
                  logoUrl
                }
                __typename
              }
            }
            isSignup
            __typename
          }
        }
      `,
      variables: {
        input: {
          id: payload.accessToken,
          mode: 'GOOGLE',
        },
      },
    });
  },
  getPaidRestrictions(apiParams) {
    return Repository.get(`/partner-usage-service/api/partner/${apiParams.id}`);
  },
  getActivityList(apiParams) {
    return Repository.post(
      `/partner-usage-service/api/partner/search?cursor=${apiParams.cursor}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`
    );
  },
};

export default apis;
