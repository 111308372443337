import Repository from './Repository';
import client from './apolloClient2';
import gql from 'graphql-tag';

const apis = {
  getCollections(body, apiParams) {
    return Repository.post(
      `profile-collection-service/api/collection/search?size=${
        apiParams.size
      }&cursor=${apiParams.cursor}&sortBy=${apiParams.sortBy || ''}`,
      body
    );
  },
  getSharedCollectionInfo(id) {
    return Repository.get(
      `profile-collection-service/api/collection/byshareid/${id}`
    );
  },
  getCollectionInfo(id) {
    return Repository.get(`profile-collection-service/api/collection/${id}`);
  },

  getCollectionProfiles(idFilter, platform, apiParams) {
    return Repository.post(
      `profile-collection-service/api/collection/item/search?size=${apiParams.size}&cursor=${apiParams.cursor}`,
      {
        filters: [
          { field: 'platform', filterType: 'EQ', value: platform },
          idFilter,
        ],
      }
    );
  },
  regenerateCollectionShareId(id) {
    return Repository.post(
      `profile-collection-service/api/collection/${id}/link/renew`
    );
  },
  deleteCollection(collectionId) {
    return Repository.delete(
      `profile-collection-service/api/collection/${collectionId}`
    );
  },
  deleteInfluencer(collectionId, data) {
    return Repository.delete(
      `profile-collection-service/api/collection/${collectionId}/item`,
      { data }
    );
  },
  addInfluencer(collectionId, data) {
    return Repository.post(
      `profile-collection-service/api/collection/${collectionId}/item/`,
      data
    );
  },
  createCollection(data) {
    return Repository.post(`profile-collection-service/api/collection/`, data);
  },
  editCollection(collectionId, data) {
    return Repository.put(
      `profile-collection-service/api/collection/${collectionId}`,
      data
    );
  },
  recentCollections() {
    return Repository.get(`profile-collection-service/api/collection/recent`);
  },
  createJob(params) {
    return client.mutate({
      mutation: gql`
        mutation createJob($jobInput: JobInput!) {
          createJob(jobInput: $jobInput) {
            id
            jobType
            jobName
            remark
            inputFileUrl
            outputFileUrl
            hasFailures
            status
          }
        }
      `,
      variables: {
        jobInput: params.jobInput,
      },
    });
  },
  searchJobs(query) {
    return client.query({
      query: gql`
        query SearchJobs($query: PaginatedSearchQuery) {
          SearchJobs(query: $query) {
            id
            jobName
            jobType
            completedStepCount
            inputFileUrl
            outputFileUrl
            hasFailures
            status
            scheduledTime
            totalStepCount
            createdBy
            remark
            createdOn
            context
            jobIdentifier
            jobBatches {
              id
              status
              remarks
              jobBatchItems {
                id
                inputNode
                outputNode
                remarks
                status
              }
            }
          }
        }
      `,
      variables: {
        query,
      },
    });
  },
};

export default apis;
