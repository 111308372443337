import Repository from './Repository';
import axios from 'axios';

const CancelToken = axios.CancelToken;

var apiRequestsToken = {};

function setApiRequestToken(payload) {
  if (!(payload.key in apiRequestsToken)) {
    createApiToken(payload);
  } else updateApiToken(payload);
}

function createApiToken(payload) {
  apiRequestsToken[payload.key] = {
    activeToken: payload.token,
  };
}

function updateApiToken(payload) {
  let cancel = apiRequestsToken[payload.key]['activeToken'];
  apiRequestsToken[payload.key]['activeToken'] = payload.token;
  cancel();
}

const apis = {
  getInfluencers(body, apiParams) {
    return Repository.post(
      `discovery-service/api/profile/${apiParams.platform}/search?cursor=${
        apiParams.cursor
      }&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${
        apiParams.sortDir
      }&activityId=${apiParams.activityId ? apiParams.activityId : null}`,
      body,
      {
        cancelToken: new CancelToken(function executor(c) {
          setApiRequestToken({
            key: `discovery-service/api/profile/${apiParams.platform}/search?cursor=${apiParams.cursor}&size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`,
            token: c,
          });
        }),
      }
    );
  },
  getPastActivities(body, apiParams) {
    return Repository.post(
      `activity-service/api/activity/search?size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`,
      body,
      {
        cancelToken: new CancelToken(function executor(c) {
          setApiRequestToken({
            key: `activity-service/api/activity/search?size=${apiParams.size}&sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}`,
            token: c,
          });
        }),
      }
    );
  },
  getPastActivity(apiParams) {
    return Repository.get(`activity-service/api/activity/${apiParams.id}`);
  },
  getFilterLocations(body, apiParams) {
    return Repository.post(
      `discovery-service/api/profile/locations_new?sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}&size=${apiParams.size}&cursor=${apiParams.cursor}`,
      body,
      {
        cancelToken: new CancelToken(function executor(c) {
          setApiRequestToken({
            key: `discovery-service/api/profile/locations_new?sortBy=${apiParams.sortBy}&sortDir=${apiParams.sortDir}&size=${apiParams.size}&cursor=${apiParams.cursor}`,
            token: c,
          });
        }),
      }
    );
  },
};

export default apis;
