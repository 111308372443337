import Repository from './Repository';

const apis = {
  getTemp(params) {
    return Repository.get('api/f1/2018/circuits.json', {
      params,
    });
  },
};

export default apis;
